import React from 'react';
import {
  BrowserRouter as Router,
  Route,Switch
} from 'react-router-dom'
import './App.scss';
import Loadable from 'react-loadable';
import CookieConsent from "react-cookie-consent";
import CacheBuster from './CacheBuster';

const loading = () => <div className="" >Loading...</div>;
const ProductConferenceLayout = Loadable({
  loader: () => import('./containers/DefaultLayout/ProductConferenceLayout'),
  loading
});
const ProductConference = React.lazy(() => import('./views/ProductConference/ProductConference'));

// const Scrolled = React.lazy(() => import('./views/Scrolled/Scrolled'));
// const Public = () => <h3>Public</h3>

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)
export default function AuthExample () {
  return (
    <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <Router>
              <div>
                <CookieConsent>
                    This website uses cookies
                    We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. Visit our <a className="blue-color" href='/terms-and-conditions'> terms and conditions </a> for more information.
                </CookieConsent>
                <Switch>
                  <AppRoute path="/product-thinking-conference" layout={ ProductConferenceLayout } component={ ProductConference } />
                  <AppRoute path="/" layout={ ProductConferenceLayout } component={ ProductConference } />
                </Switch>
              </div>
            </Router>
          );
        }}
      </CacheBuster>
    )
}
